.more-orders {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  color: #666;
  font-size: 14px;
}

.more-orders__link {
  margin-left: 4px;
  color: #3dbda3 !important;
}
