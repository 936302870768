.shop-info-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
}

.shop-info-logo {
  width: 55px;
  height: 55px;
  object-fit: contain;
  border-radius: 50%;
}

.shop-info-text {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 12px;
  text-align: left;
  overflow: hidden;
}

.shop-info-name {
  font-size: 16px;
  font-weight: 500;
  color: var(--color, rgba(0, 0, 0, 0.85));
}
.shop-info-desc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.25;
  margin-top: 8px;
  font-size: 12px;
  color: var(--color, rgba(0, 0, 0, 0.65));
}

.shop-info-logo,
.shop-info-contact {
  flex-shrink: 0;
}

.shop-info-icons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 28px;
  margin-top: 8px;
}
.shop-info-icons > img + img {
  margin-left: 4px;
}
.shop-info-icon-solid {
  width: 100px;
  height: 26px;
  object-fit: contain;
}
.shop-info-icon-old {
  width: 75px;
  height: 23px;
  object-fit: contain;
}

.shop-info-redirect {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin-bottom: 6px;
  border-radius: 15px;
  background-color: #9ccec3;
  font-size: 12px;
  color: #fff;
}
