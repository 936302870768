.search-top-home {
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 20px;
  z-index: 309;
  max-width: 768px;
  color: #333;
  background-color: var(--bg-color, #fff);
}
.search-top-home a {
  text-align: left;
  font-size: 14px;

  overflow: hidden;
  display: block;
  padding-right: 10px;
}

.search-top-home .search-right img {
  width: 250px;
  max-height: 40px;
  float: right;
}
.search-top a {
  text-align: left;
  width: 10%;
  font-size: 14px;
  color: #333;
}
.search-top-home .search-right {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  padding-left: 10px;
}
.search-top .search-right img {
  width: 100%;
  max-width: 400px;
  max-height: 40px;
}
.nav-intro-btn {
  width: 100%;
  background-size: cover;
}
.search-nav-leading {
  display: flex;
  justify-content: space-between;
}
.navbox {
  flex: 1;
  height: 45px;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
}
.navbox li {
  display: inline-block;
  line-height: 45px;
  padding: 0 9px;
  color: var(--nav-color, currentColor);
}
.navbox .cur {
  height: 43px;
  border-bottom: 2px solid var(--nav-active-color, #41a793);
  color: var(--nav-active-color, #41a793);
}
.goodsListWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 8px 6px 0 6px;
  background-color: #f4f4f4;
}
.goodsListWrap li {
  width: 49%;
  background-color: #fff;
  margin: 7px 0;
  border-radius: 1px;
}
.top-search-frame {
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
  align-items: center;
}
.no-more {
  padding-top: 12px;
  padding-bottom: 62px;
}
.seckill-row-reminder {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  font-size: 12px;
  background-color: #ff6d8c;
  align-items: flex-end;
}
.seckill-row-reminder img {
  width: 70px;
}
.seckill-row-reminder .cd-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #f8c985;
}
.seckill-row-reminder .cd-date {
  margin-right: 16px;
  color: white;
}
.seckill-row-reminder .cd {
  padding: 0 2px;
  border-radius: 3px;
  background: #f8c985;
  color: #ff6d8c;
  text-align: center;
  font-size: 10px;
}
.seck-icon {
  padding: 2px;
  border-radius: 4px;
  background-color: #ff6d8c;
  color: #fff;
  margin-right: 6px;
}
.max-sell-price {
  margin-left: 16px;
}
.seckill-row-reminder .colon {
  margin: 0 3px;
}

.shop-icon {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.solid-shop-icon {
  width: 100px;
  margin-top: 5px;
  margin-right: 8px;
}

.clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
