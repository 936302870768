#tab-bar.demo {
  display: flex;
  flex-direction: column;
}
#tab-bar .demoName {
  height: 40px;
}
#tab-bar .demo-preview-item .am-tab-bar {
  background-color: white;
}
body .am-modal-close {
  top: 8px;
}
.login-top {
  height: 30vh;
  max-height: 300px;
  background-image: linear-gradient(to right, #63ceb9, #44baae);
  position: relative;
}
.login-top h3 {
  width: 100%;
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  top: 30%;
  left: 0;
}
.login-box {
  width: 300px;
  height: 300px;
  padding: 8px;
  border-radius: 6px;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 0px 3px 3px #e8e6e6;
  overflow: hidden;
}
.login-box img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.login-tip {
  position: absolute;
  top: calc(90% + 316px);
  width: 100%;
  color: #44baae;
}
.old-shop-icon {
  margin-top: 5px;
  width: 75px;
}
.personal-center-box {
  width: 90%;
  height: 300px;
  border-radius: 6px;
  position: absolute;
  top: 90%;
  left: 5%;
  background-color: #fff;
  box-shadow: 0px 3px 3px #e8e6e6;
}
.login-input {
  box-shadow: 0px 3px 3px #e8e6e6;
  border-radius: 10px;
}
.login-input input {
  border-radius: 10px;
}
.login-btn {
  width: 100%;
  display: block;
  margin: 0 auto;
  background-color: #44baae;
  box-shadow: 0px 2px 2px #44baae;
  color: #fff;
  font-size: 16px;
  border-radius: 13px;
  height: 40px;
  line-height: 40px;
}
.loading-example {
  padding-top: 200px;
}
.loading-example > div {
  justify-content: center;
}
.search-top {
  position: fixed;
  top: 0;
  max-width: 768px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  z-index: 100;
  padding: 3px 0 5px 0;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  transform: scale3d(1, 1, 1);
}
.search-top .search-input {
  flex-grow: 1;
  height: 35px;
  align-self: center;
}
.search-top .search-input input {
  border-radius: 20px;
  background-color: #f7f7f7;
}
.goods_detail img {
  max-width: 100% !important;
}
.hot-search {
  font-size: 20px;
  height: 25px;
  line-height: 25px;
  position: relative;
  padding-left: 10px;
}
.hot-search:before {
  content: '';
  width: 3px;
  height: 100%;
  background-color: #44baae;
  position: absolute;
  left: 0;
  top: 0;
}
.search-btn-wrap {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.search-btn-wrap button {
  border-radius: 20px;
  height: 30px;
  line-height: 30px;
  background: #e5e5e5;
  padding: 0 15px;
  margin: 8px 0 0 15px;
}
.order-icon-box {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.order-icon-box li {
  width: 33%;
}
.order-icon-box li a {
  width: 100%;
  text-align: center;
  height: 100px;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.order-icon-box li a img {
  width: 40px;
  align-self: center;
}
.order-icon-box li a span {
  align-self: center;
  color: #4a4a4a;
  margin-top: 5px;
}
.order-icon-box li a b {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fa5150;
  line-height: 15px;
  color: #fff;
  position: absolute;
  top: 10%;
  right: 30%;
  font-size: 12px;
  font-weight: normal;
}
.personal-info {
  width: 90%;
  margin: 20% auto 0 auto;
  position: relative;
  display: flex;
}
.personal-info a {
  position: absolute;
  top: -60%;
  right: 0;
  color: #fff;
  font-size: 16px;
}
.personal-info img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  align-self: center;
}
.personal-info div {
  align-self: center;
  margin-left: 10px;
  color: #fff;
}
.cate-wrap {
  margin-top: 30px;
  font-size: 14px;
}
.cate-wrap a {
  height: 35px;
  line-height: 35px;
  overflow: hidden;
  padding: 0 5%;
}
.cate-wrap a img {
  width: 30px;
  height: 30px;
}
.cate-wrap a span {
  font-size: 14px;
  margin-left: 10px;
}
.cate-wrap svg {
  color: #cdced2;
}
.price-tag-wrap {
  padding: 20px 0;
  height: 70px;
  display: flex;
  justify-content: space-between;
}
.price-tag-wrap li {
  text-align: left;
  width: 33%;
}
.price-tag-wrap li p {
  color: #c83b4c;
  font-size: 16px;
  font-weight: bold;
}
.price-tag-wrap li span {
  font-size: 12px;
}

.cbox-wraper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cbox-wraper li {
  font-size: 12px;
  width: 49%;
  margin: 5px 0;
}
.cbox::selection {
  background: #c83b4c;
}
.cbox span {
  margin-right: 3px;
}

.cart-cbox::selection {
  background: #f4c47d;
}
.cart-cbox span {
  margin-right: 3px;
}
.cart-cbox .am-checkbox-checked .am-checkbox-inner {
  background: #f4c47d !important;
  background-color: #f4c47d !important;
  border-color: #f4c47d !important;
}

body .am-checkbox.am-checkbox-checked .am-checkbox-inner {
  background: #f4c47d;
  background-color: #f4c47d;
  border-color: #f4c47d;
}
.purchase-remind {
  display: inline-block;
  position: relative;
  text-align: center;
  margin: 0 auto;
  font-size: 15px;
}
.purchase-remind:before {
  content: '';
  width: 70px;
  position: absolute;
  top: 50%;
  left: -80px;
  background-color: #bcbcbc;
  height: 1px;
}

.purchase-remind:after {
  content: '';
  width: 70px;
  position: absolute;
  top: 50%;
  right: -80px;
  background-color: #bcbcbc;
  height: 1px;
}
.g-fixed-menu {
  height: 50px;
  position: fixed;
  max-width: 768px;
  width: 100%;
  bottom: 0;
  background-color: #fff;
  z-index: 231;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 8px;
}
.open-mini-btn {
  position: fixed;
  bottom: 60px;
  right: 10px;
  width: 40px;
  z-index: 23;
}
.purchase-script {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-self: flex-end;
  position: relative;
}
.g-btn-group {
  height: 40px;
  line-height: 40px;
  align-self: center;
  width: 75%;
  font-size: 15px;
  color: #fff;
}
.order-icon a {
  position: relative;
}
.num-tips {
  font-weight: normal;
  position: absolute;
  width: 16px;
  height: 16px;
  line-height: 16px;
  background-color: #ff4f64;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  right: 0px;
  top: -3px;
  z-index: 8920;
}
.g-btn-group .adding-btn {
  width: 50%;
  height: 100%;
  align-self: center;
  background: #9ccec3;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  color: #fff;
}
.g-btn-group .adding-btn:disabled {
  opacity: 0.7;
}
.g-btn-group .adding-btn-allLine {
  width: 100%;
  height: 100%;
  align-self: center;
  background: #ff6d8c;
  border-radius: 15px;
  color: #fff;
}
.g-btn-group .adding-btn-line {
  width: 100%;
  height: 100%;
  align-self: center;
  background: #9ccec3;
  border-radius: 18px;
  color: #fff;
}
.g-btn-group .adding-btn-line:disabled {
  opacity: 0.7;
}
.g-btn-group .purchase-now-btn {
  width: 50%;
  height: 100%;
  align-self: center;
  background: #c83b4c;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  color: #fff;
}
.g-btn-group .purchase-now-btn:disabled {
  opacity: 0.7;
}
.service-content-modal {
  position: relative;
  text-align: left;
  color: #333;
  overflow: hidden;
}
.service-content-title {
  padding: 15px 0 8px;
  text-align: center;
  font-size: 16px;
  color: #222;
}
.service-content {
  min-height: 200px;
  max-height: 450px;
  padding: 15px 15px 68px;
  overflow-y: scroll;
}
.service-content p {
  margin-bottom: 7px;
}
.service-content-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  background-color: #fff;
  z-index: 1;
}
.service-content-btn {
  height: 38px;
  line-height: 38px;
  border-radius: 19px;
  background-color: #44baae;
  text-align: center;
  color: #fff;
}
.chose-incomes-type {
  height: 40px;
  line-height: 40px;
  border-top: 1px solid #edebeb;
  border-bottom: 1px solid #edebeb;
  overflow: hidden;
}
body .theme-color {
  color: #44baae !important;
  padding-bottom: 3px;
}
body .theme-yellow-color {
  color: #eb9c2e !important;
  padding-bottom: 3px;
}
.chose-incomes-type .whole-in {
  position: relative;
}
.chose-incomes-type button {
  height: 100%;
  line-height: 40px;
}
.chose-incomes-type .whole-in:after {
  content: '';
  height: 30px;
  position: absolute;
  width: 1px;
  background: #edebeb;
  right: 0;
  top: 5px;
}

.chose-spec-title {
  display: flex;
  justify-content: space-between;
}
.chose-spec-title h5 {
  text-align: left;
}
.chose-spec-wrap li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
}
.chose-spec-wrap li span {
  align-self: center;
}

.goods-sub-fixed {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 5px;
  height: 60px;
  z-index: 300;
  background-color: #fff;
}
.goods-sub-fixed button {
  align-self: center;
  border-radius: 7px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  width: 45%;
}
.checked-add-btn {
  background-color: #9ccec3;
}
.checked-atonce-btn {
  background-color: #c83b4c;
}
.unchecked-add-btn {
  background-color: #bcbcbc;
}

.chosed-spec-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}
.chosed-spec-wrap li {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #edebeb;
  padding: 3px 0;
}
.chosed-spec-wrap li p:first-child {
  flex-basis: 37%;
}
.chosed-spec-wrap li:last-child {
  border-bottom: none;
}
.cartlist-bottom {
  background-color: #fff;
  position: fixed;
  bottom: 50px;
  width: 100%;
  height: 50px;
  display: flex;
  z-index: 68;
  justify-content: space-between;
  padding-left: 15px;
  border-top: 1px solid #eaeaea;
}
.order-confirm-bottom {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  padding-left: 15px;
  border-top: 1px solid #eaeaea;
  z-index: 36;
}
.white-background {
  background-color: #fff;
}
.grey-background {
  background-color: #f4f4f4;
}
.sec_wrap {
  display: flex;
  text-align: center;
  height: 50px;
  line-height: 50px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 908;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}
.sec_wrap .sec_act {
  background-color: #f03c62;
  color: #fff;
}
.sec_list_box {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}
.sec_list_box li {
  width: 100%;
  max-width: 420px;
  margin-bottom: 20px;
  position: relative;
}
.sec_list_box li .time_tag {
  position: absolute;
  left: 0;
  top: 0;
  padding: 3px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0;
  font-size: 14px;
  color: #fff;
}
.sec_item_wraper {
  display: flex;
  flex-direction: column;
  position: relative;
}
.sec_item_wraper img {
  width: 100%;
}
.sec_goods_name {
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.twolines-hidden {
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.want_more {
  display: flex;
  justify-content: space-between;
  color: #f03c62;
  padding: 0 8px;
}
.goods_name_p {
  padding: 0 3px;
  color: #333;
  font-size: 16px;
  margin-bottom: 0;
  min-height: 40px;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.specifications {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  padding: 8px 0;
  text-align: left;
}
.specifications-text {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-right: 16px;
}
.specifications-label {
  flex-shrink: 0;
  margin-right: 12px;
  color: #9c9c9c;
}

.contactLord {
  padding: 0;
  width: 70px;
  height: 26px;
  border-radius: 16px;
  border: 1px solid #9ccec3;
  text-align: center;
  line-height: 22px;
  color: #9ccec3;
  font-size: 12px;
  margin-right: 5px;
}
.takeLook {
  padding: 0;
  width: 85px;
  height: 30px;
  border-radius: 16px;
  border: 1px solid #9ccec3;
  text-align: center;
  line-height: 22px;
  color: #fff;
  font-size: 12px;
  background: #9ccec3;
}
.shop-infos-display {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.row-line-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  border-bottom: 1px solid #f4f4f4;
}
.pale-row-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.copy-btn {
  border: 1px solid #36a28d;
  color: #36a28d;
  border-radius: 13px;
  padding: 0 8px;
  margin-left: 5px;
}
.column-line-box {
  display: flex;
  flex-direction: column;
}
.proevatitle {
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f4f4f4;
}

.proevatitle .standardmore {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.proevatitle .standardmore img {
  width: 8px;
  height: 12px;
}
.proevatitle .rprecent {
  color: #ff4f64;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
}
.evaluate-page {
  padding: 20px 0;
}
.comment-photo {
  display: flex;
  flex-wrap: nowrap;
  width: max-content;
  overflow: hidden;
}
.comment-photo li {
  margin: 3px;
  width: 130px;
  height: 110px;
  overflow: hidden;
}
/* TODO: 评论图片是否需要展示在正方形框中？ */
.comment-photo li img {
  width: 100%;
}
.goods-edit-modal {
  overflow: hidden;
  padding: 12px 0;
}
.goods-edit-modal .spec-btn {
  padding: 4px 15px 3px 15px;
  background-color: #fff;
  background: #f5f5f5;
  color: #333;
  border-radius: 45px;
  margin-right: 18px;
  margin-top: 10px;
  font-size: 14px;
}
.goods-edit-modal .spec-disabled-btn {
  padding: 4px 15px 3px 15px;
  background-color: #f6f6f6;
  color: #b6b6b6;
  border-radius: 45px;
  margin-right: 18px;
  margin-top: 10px;
  font-size: 14px;
}
.goods-edit-modal .spec-btn-active {
  padding: 4px 15px 3px 15px;
  border-radius: 45px;
  margin-right: 18px;
  margin-top: 10px;
  font-size: 14px;
  color: #fff;
  background-color: #9ccec3;
}
.goods-edit-modal .next-btn-group {
  width: 100%;
  z-index: 399;
  padding: 0 10px;
}
.inevitable-login-btn:before {
  border-color: #9ccec3 !important;
}
body .inevitable-login-btn:hover {
  color: #f7f7f7;
}
.reg-page input {
  height: 55px;
}
.contact-mobile-wrap {
  margin: 10px auto;
  background: #e5e5e5;
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: left;
}
.contact-mobile-wrap img {
  align-self: center;
  width: 82px;
  height: 79px;
}

.contact-mobile-wrap h3 {
  font-size: 18px;
  font-weight: bold;
  color: #969696;
}
.contact-mobile-wrap p {
  font-size: 14px;
  color: #7b7b7b;
}
.contact-mobile-f {
  display: flex;
  justify-content: space-between;
}
.contact-mobile-f .contact-mobile-num {
  background: transparent;
  font-size: 18px;
  font-weight: bold;
  color: #44baae;
  width: 140px;
}
.contact-mobile-f button {
  height: 23px;
  line-height: 23px;
  color: #44baae;
  border: 1px solid #44baae;
  border-radius: 16px;
  padding: 0 10px;
}
.tip-gift {
  background: #fff8e6;
  border-radius: 3px;
  font-size: 14px;
  color: #e76f3f;
  padding: 8px 12px;
  margin-bottom: 20px;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.exchange-icon {
  display: inline-block;
  width: auto !important;
  height: 14px !important;
  margin-right: 2px !important;
  vertical-align: -0.125em;
}

/* 指示器容器样式，用于列表底部“加载中”，“到底咯”等状态的布局 */
.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: #666;
  user-select: none;
}

/* 样式覆盖，解决 iOS 下弹窗的层级问题 */
.am-modal-mask {
  transform: translateZ(1px);
}
