.package-page {
  background-color: #fff;
  min-height: 100vh;
}
.package-box {
  margin-bottom: 6px;
  background-color: #fff;
  display: flex;
  padding: 6px;
  flex-wrap: wrap;
}
.package-box .package-title {
  display: flex;
  justify-content: space-between;
}
.package-box .package-bar {
  margin: 5px 0;
  width: 100%;
}
.package-box .package-bar .bar-scroll-wrap {
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow-x: scroll;
}
.package-box .pack-img-wrap {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin: 0 4px;
  flex-shrink: 0;
}
.package-list-inline {
  display: flex;
  flex-wrap: wrap;
}
.look-package-detail {
  color: #29ae9d;
}
.package-box .pack-img-wrap span {
  position: absolute;
  width: 100%;
  height: 25px;
  background: rgba(0, 0, 0, 0.6);
  line-height: 25px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  z-index: 12;
  bottom: 0;
  left: 0;
}
.package-box .pack-img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
