.message__form {
  min-height: 100vh;
  padding: 10px 0;
  background-color: #f6f6f6;
  font-size: 16px;
  color: #333;
}

.message__item {
  line-height: 1;
  margin-bottom: 10px;
  padding: 16px;
  background-color: #fff;
}
.message__item--order-code {
  display: flex;
  align-items: center;
}

.message__order-code {
  flex-grow: 1;
  margin-left: 16px;
}

.message__tip {
  margin-top: 8px;
  padding: 0 16px;
  font-size: 12px;
  color: #666;
}
.message__tip--red {
  color: #ed3030;
}

.message__btn {
  position: fixed;
  left: 16px;
  bottom: calc(16px + constant(safe-area-inset-bottom));
  bottom: calc(16px + env(safe-area-inset-bottom));
  width: calc(100% - 32px);
  height: 45px;
  line-height: 45px;
  background: #44baae;
  border-radius: 22.5px;
  font-size: 16px;
  color: #fafafa;
}

.message__form .am-list-item {
  padding-left: 0;
}
.message__form .am-textarea-control textarea {
  line-height: 1.5;
  font-size: 16px;
}
.message__form .am-textarea-count span {
  color: #bbb;
}

.message__form .am-list-item.am-input-item {
  height: auto;
  min-height: 0;
}
.message__form .am-list-item .am-input-control input {
  font-size: 16px;
}
.message__form .am-list-item .am-input-clear {
  width: 16px;
  height: 16px;
  background-size: 100%;
  background-position: 1.5px 1.5px;
}
