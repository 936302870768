.lianlian-receipt {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  font-size: 0.875rem;
  color: #333;
}

.lianlian-receipt__success {
  width: 4rem;
  height: 4rem;
}

.lianlian-receipt__text {
  margin-top: 1rem;
  font-size: 1.25rem;
  font-weight: 500;
}

.lianlian-receipt__rows {
  margin-top: 2.5rem;
}
.lianlian-receipt__row {
  display: flex;
  align-items: flex-start;
}
.lianlian-receipt__row + .lianlian-receipt__row {
  margin-top: 0.5rem;
}
.lianlian-receipt__label {
  flex-shrink: 0;
  margin-right: 1rem;
  color: #999;
}
.lianlian-receipt__value {
  word-break: break-all;
}

.lianlian-receipt__button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  margin-top: 2.5rem;
  padding: 0 1.25rem;
  font-size: 1rem;
  color: #fff;
  background-color: #44baae;
  border-radius: 1.125rem;
}
