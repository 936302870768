.ad-wrap {
  padding: 10px;
  background-color: #fff;
}
.ad-top {
  padding-bottom: 10px;
  border-bottom: 1px solid #e7e7e7;
}
.ad-top-info {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding-bottom: 5px;
}
.ad-bot {
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
}
.handle-address {
  display: flex;
}
.fixed-bottom-button {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 768px;
  height: 60px;
  padding-bottom: var(--safe-area-inset-bottom, 0);
  background-color: transparent;
  box-sizing: content-box;
  z-index: 21;
}
.fixed-bottom-button a,
.fixed-bottom-button button {
  width: 80%;
  margin: 0 auto;
  display: block;
  border-radius: 25px;
  height: 45px;
  left: 10%;
  line-height: 45px;
  background: #44baae;
  color: #fff !important;
  font-size: 16px;
  text-align: center;
}
body .am-list-item .am-list-line .am-list-extra {
  flex-basis: initial;
}
.check-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 5px 7px 0;
  font-size: 15px;
  color: #969696;
}
.check-info-add {
  font-size: 14px;
  padding: 0 5px 5px 0;
  white-space: initial;
  color: #969696;
}
.order-modal {
  display: flex;
  padding: 10px;
  background: #fff;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.order-modal img {
  width: 70px;
}
.order-modal h3 {
  font-size: 20px;
  color: #44baae;
  font-weight: bold;
}
body .order-modal a {
  display: block;
  background: #44baae;
  width: 70%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 20px;
  color: #fff;
}
.order-modal a img {
  width: 20px;
  padding-right: 5px;
}
.order-m-bottom {
  text-align: left;
  margin-top: 10px;
  position: relative;
  padding-top: 10px;
}
.order-m-bottom:after {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  background-color: #e7e7e7;
  content: '';
}
.buy-goods-box {
  display: flex;
  color: #323232;
  word-wrap: break-word;
  padding: 12px;
  position: relative;
}
.buy-goods-box .buy-goods-image {
  width: 90px;
  height: 90px;
  text-align: right;
}
.buy-goods-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.buy-goods-box .buy-goods-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
  margin-left: 12px;
}
.buy-goods-box .buy-goods-info .g-titles {
  white-space: pre-wrap;
  font-size: 16px;
  line-height: 20px;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.first-sub-bottom {
  width: 100%;
  height: 50px;
  background: #fff;
  position: fixed;
  z-index: 333;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eaeaea;
}
.first-sub-bottom div:first-child {
  display: flex;
  margin-left: 30px;
  font-size: 16px;
}
.first-sub-bottom .first-sub-btn {
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  background: #fe5064;
  width: 120px;
  line-height: 28px;
  border-radius: 25px;
  padding: 6px;
  margin-right: 6px;
  height: 40px;
}
.first-sub-bottom .first-sub-disabled-btn {
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  width: 120px;
  line-height: 28px;
  border-radius: 25px;
  padding: 6px;
  margin-right: 6px;
  height: 40px;
  background-color: #fea7b0;
}
.cart-item {
  width: 92%;
  background: #fff;
  border-radius: 10px;
  margin: 15px auto;
  padding: 5px 10px;
}
.cart-item > p {
  text-align: center;
  color: #ff6d8c;
  font-size: 12px;
}
.cart-item-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cart-item-untouch {
  position: relative;
}
.untouchable-modal {
  width: 80%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 410;
  background: transparent;
}
.cart-item .cart-img-box {
  width: 27%;
  margin: 0 8px;
}
.cart-item img {
  width: 100%;
  border-radius: 5px;
}
.cart-item .cart-item-inner {
  width: 73%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cannot-calculate {
  margin: 12px 0;
  color: #969696;
  font-size: 12px;
  text-align: center;
  position: relative;
}
.cannot-calculate:before {
  content: '';
  height: 1px;
  width: 25%;
  position: absolute;
  left: 15%;
  top: 50%;
  background: #cfcece;
}
.cannot-calculate:after {
  content: '';
  height: 1px;
  width: 25%;
  position: absolute;
  right: 15%;
  top: 50%;
  background: #cfcece;
}
.cart-item-untouch .untouch-image-wrap {
  position: relative;
  width: 27%;
  margin: 0 8px;
}
.cart-item-untouch .untouch-image-wrap > div {
  color: #eaeaea;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 562;
  text-align: center;
  line-height: 70px;
}
.cart-item-untouch .untouch-image-wrap img {
  width: 100%;
  height: 100%;
  margin: 0;
}
.cart-item .sec-kill-icon {
  padding: 2px;
  color: #fff;
  text-align: center;
  line-height: 18px;
  border-radius: 5px;
  background: #ff6d8c;
  font-size: 10px;
}
.top-change-tip {
  color: #ed601a;
  background-color: #fff5d0;
  padding: 8px 10px;
}
#root .address-title {
  height: 72px;
}
.buy-step-goods-item {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.buy-goods-box .step-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(216, 216, 216, 0.5);
  z-index: 90;
}
.buy-goods-box .step-cover img {
  position: absolute;
  right: 15%;
  width: 90px;
  height: 73px;
  bottom: 0;
  z-index: 21;
}
.buy-goods-box .step-cover span {
  position: absolute;
  left: 12px;
  bottom: 5px;
  z-index: 21;
  color: #fe5064;
}
.pd-24 {
  padding-bottom: 24px;
}

.address-bottom-buttons {
  display: flex;
  gap: 16px;
}
.address-bottom-buttons > * {
  flex: 1;
}

.auto {
  position: relative;
  padding: 16px 16px 0;
}
.auto__textarea {
  padding-bottom: 40px;
  border: 1px solid #eee;
  border-radius: 8px;
  background-color: #f5f5f5 !important;
}
.auto__button {
  position: absolute;
  bottom: 8px;
  right: 24px;
}
