.list-card {
  display: flex;
  height: 90px;
  margin-bottom: 16px;
  background-color: #fff;
}

.list-cover {
  position: relative;
  flex-shrink: 0;
  width: 90px;
  height: 90px;
  margin-right: 8px;
  border-radius: 5px;
  overflow: hidden;
}
.list-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.list-cover-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  color: #fff;
}

.list-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  overflow: hidden;
}

.list-name {
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-action {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.list-add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 32px;
  background: #44baae;
  border-radius: 16px;
  color: #fff;
}
.list-add-btn.list-add-btn--disabled {
  background-color: #d2cccc;
}

.list-cancel-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 32px;
  border: 1px solid #ed3030;
  border-radius: 16px;
  color: #ed3030;
}

.list-selected {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 10px;
  color: #666;
}

.list-selected-items {
  display: flex;
  flex-grow: 1;
}

.list-selected-item {
  display: flex;
  flex-direction: column;
  width: 22.5%;
}
.list-selected-item + .list-selected-item {
  margin-left: 3.3%;
}

.list-selected-cover {
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin-bottom: 4px;
  border-radius: 5px;
  overflow: hidden;
}
.list-selected-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.list-selected-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 16px;
  line-height: 16px;
  background: rgba(238, 238, 238, 0.7);
  backdrop-filter: blur(5px);
  text-align: center;
}

.list-selected-name {
  height: 30px;
  line-height: 15px;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.list-selected-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 55px;
  margin-left: 12px;
  padding-left: 5px;
  background: #f6f6f6;
  border-radius: 10px;
}
