.home__empty {
  margin-top: 20vh;
}

.home__header {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 768px;
  background-color: #fff;
  background-color: var(--bg-color, #fff);
  z-index: 1;
}

.home__shop {
  padding: 16px 12px 0;
}

.home__nav {
  display: flex;
}

.home__search {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 0 10px;
}
.home__search__image {
  display: block;
  width: 28px;
  height: auto;
}

.home__categories {
  flex-grow: 1;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.home__category {
  display: inline-block;
  height: 44px;
  line-height: 42px;
  padding: 0 10px;
  border-bottom: 2px solid transparent;
  color: currentColor;
  color: var(--nav-color, currentColor);
  transition: all 0.25s;
}
.home__category--active {
  border-bottom-color: #41a793;
  border-bottom-color: var(--nav-active-color, #41a793);
  color: #41a793;
  color: var(--nav-color, #41a793);
}

.home__content {
  min-height: 100vh;
  padding-bottom: 50px;
  background-color: #f4f4f4;
}

.home__adv {
  position: relative;
  padding-top: 40%;
  background-color: #ececec;
}
.home__adv__image {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home__adv--bottom {
  padding: 8px 8px 0;
}

.home__adv--modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home__adv--modal__body {
  position: relative;
  width: 70vw;
}
.home__adv--modal__close {
  position: absolute;
  top: -32px;
  right: -12px;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 50%;
}
.home__adv--modal img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.home__list {
  display: flex;
  flex-wrap: wrap;
  padding: 4px;
}

.home__status {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.section-title {
  margin-top: 8px;
  padding: 8px;
  background-color: #fff;
  color: #333;
  font-size: 15px;
  font-weight: 600;
}

/* 热卖爆款 */
.hotsals__more {
  margin: 16px 0;
  text-align: center;
  font-size: 14px;
}
.hotsals__more__inner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: #36a28d !important;
}

.home__mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}

#codeArea > img {
  width: 100%;
  height: 100%;
}
