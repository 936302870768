.exchange {
  position: relative;
  min-height: 100vh;
  padding: 12px 0 calc(env(safe-area-inset-bottom) + 80px);
  color: #333;
  background-color: #f6f6f6;
}

.exchange .main-card {
  margin-bottom: 16px;
  padding: 16px 0;
  background-color: #fff;
}

.exchange .title {
  margin-bottom: 12px;
  padding: 0 16px;
  font-size: 20px;
  font-weight: 600;
  color: #333;
}
.exchange .time {
  line-height: 20px;
  margin-bottom: 16px;
  padding: 0 16px;
}

.exchange .exchange-card {
  padding-bottom: 16px;
  background-color: #fff;
}

.exchange .swiper-list {
  display: flex;
  padding: 0 16px;
  overflow: auto hidden;
}

.exchange .count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px;
  padding: 12px 0;
  border-bottom: 1px solid #eee;
}
.exchange .count-desc {
  flex-grow: 1;
  line-height: 20px;
  white-space: pre;
}
.exchange .count-num {
  font-size: 20px;
  font-weight: 600;
}
.exchange .count-num-less {
  color: #ee3434;
}

.exchange .list {
  padding: 16px 16px 0;
}

.exchange .cart {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 768px;
  padding: 8px 16px;
  background: #fefefe;
  box-shadow: 0px -1px 0px 0px #e7e7e7;
}
.exchange .cart-btn {
  margin-bottom: env(safe-area-inset-bottom);
}
