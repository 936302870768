.order-history {
  min-height: 100vh;
  padding: 10px 0;
  background-color: #f4f4f4;
}

.order-history .order-item:first-child {
  margin-top: 0;
}

.order-history__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
.order-history__empty__text {
  margin: 8px 0 20px;
  color: rgba(47, 178, 157, 0.5);
}
