.user__list {
  padding-top: 16px;
}

.user__avatar {
  width: 40px !important;
  height: 40px !important;
  object-fit: contain;
  border-radius: 50%;
}

.user__bottom {
  line-height: 32px;
  text-align: center;
}
.user__link {
  color: #3dbda3 !important;
}
.user__exit {
  color: #3dbda3;
  user-select: none;
}

.user__list .am-list-item .am-list-line .am-list-extra {
  flex-basis: auto;
}
